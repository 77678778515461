<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="通用套餐名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="共享属性">
            <a-select
              v-decorator="['traffic_share_property']"
              allow-clear
            >
              <a-select-option
                v-for="option in trafficSharePropertyOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="有效性">
            <a-select
              v-decorator="['effective', { initialValue: '1' }]"
              allow-clear
            >
              <a-select-option key="1" value="1">
                有效
              </a-select-option>
              <a-select-option key="0" value="0">
                无效
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findTrafficSharePropertyOptions } from '@/api/common_options'

export default {
  name: 'SearchBaseCarrierProduct',
  data() {
    return {
      roleOptions: [],
      trafficSharePropertyOptions: findTrafficSharePropertyOptions(),
      form: this.$form.createForm(this, { name: 'base_carrier_product_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },
    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
