<template>
  <div>
    <search-base-carrier-product @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('base_carrier_products/new')"
        >新增</a-button>

        <update-base-carrier-product-effective
          v-if="isShowMenu('base_carrier_products/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>
        </a-space>
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.id)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="showOperationHistoryModal(record.id)">操作历史</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增模态框 -->
    <new-base-carrier-product
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-base-carrier-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="BaseCarrierProduct"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findBaseCarrierProducts, exportBaseCarrierProducts } from '@/api/base_carrier_product'
import SearchBaseCarrierProduct from '@/views/base_carrier_products/Search'
import Pagination from '@/components/Pagination'
import { exportExcel } from '@/api/excel'

export default {
  name: 'BaseCarrierProductList',
  components: {
    SearchBaseCarrierProduct,
    Pagination,
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    NewBaseCarrierProduct: () => import('@/views/base_carrier_products/New'),
    EditBaseCarrierProduct: () => import('@/views/base_carrier_products/Edit'),
    UpdateBaseCarrierProductEffective: () => import('@/views/base_carrier_products/UpdateEffective')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingId: 0, // 正在编辑的记录id
      isShowOperationHistoryModal: false, // 是否显示操作历史弹窗
      showingOperationHistoryId: 0, // 正在显示操作历史的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1'
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '通用套餐名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '标签',
          dataIndex: 'tags'
        },
        {
          title: '共享属性',
          dataIndex: 'traffic_share_property'
        },
        {
          title: '服务周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        },
        {
          title: '关联卡数(不含销卡)',
          dataIndex: 'cards_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cards_count' ? this.sort.sort_order : false
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('base_carrier_products/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(userId) {
      this.editingId = userId
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findBaseCarrierProducts(Object.assign({}, this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },
    exportData() {
      exportBaseCarrierProducts(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
